import { TConfigurableColor } from '../contentfulPage.types'
import {
  TConfigurableTextAlign,
  TConfigurableTextSize,
  TConfigurableTextStyle,
} from './ConfigurableText.types'

const textSizeToClass: Record<TConfigurableTextSize, string> = {
  XS: 'text-base leading-snug',
  S: 'text-lg leading-snug',
  BASE: 'text-xl leading-snug',
  M: 'text-[16px] leading-snug',
  L: 'text-[18px] leading-snug',
  XL: 'text-[20px] leading-snug',
  '2XL': 'text-[22px] leading-snug',
  '3XL': 'text-[24px] leading-snug',
  '4XL': 'text-[26px] leading-snug',
  '5XL': 'text-[28px] leading-snug',
  '6XL': 'text-[32px] leading-snug',
  '7XL': 'text-[40px] leading-snug',
  '8XL': 'text-[48px] leading-snug',
  '9XL': 'text-[56px] leading-snug',
  '10XL': 'text-[64px] leading-snug',
  '11XL': 'text-[72px] leading-snug',
  '12XL': 'text-[48px] lg:text-[64px] xl:text-[80px] leading-snug',
}

const textColorToClass: Record<TConfigurableColor, string> = {
  acai: 'text-acai',
  boulder: 'text-boulder',
  white: 'text-white',
  black: 'text-black',
  pebble: 'text-pebble',
  sand: 'text-sand',
  paper: 'text-paper',
  pitaya: 'text-pitaya',
  rambutan: 'text-rambutan',
}

const textStyleToClass: Record<TConfigurableTextStyle, string> = {
  bold: 'font-bold',
  semibold: 'font-semibold',
  normal: 'font-normal',
}

const textAlignToClass: Record<TConfigurableTextAlign, string> = {
  left: 'text-left',
  right: 'text-right',
  center: 'text-center',
}

/**
 * Creates a proxy for an object that intercepts property access and provides a fallback value for non-existent properties.
 *
 * @param {Object} initialObject - The initial object to be proxied
 * @param {*} fallbackValue - The value to return when a property is not found on the initial object
 * @param {Function} [onFallback] - An optional callback function that is invoked when a property is not found
 * @returns {Object} A new proxied object that returns the `fallbackValue` for missing properties
 */
export const proxifyWithFallbackValue = ({
  initialObject,
  fallbackValue,
  onFallback = null,
}: {
  initialObject: any
  fallbackValue: any
  onFallback?: (prop: string) => any
}) => {
  const handler = {
    get(target, prop, receiver) {
      if (Reflect.has(target, prop)) {
        return Reflect.get(target, prop, receiver)
      } else {
        if (onFallback) {
          const dynamicFallback = onFallback(prop)

          if (dynamicFallback) {
            return dynamicFallback
          }
        }
        console.warn(
          `Prop ${prop} doesn't exist in target object. Defaults to ${fallbackValue}`
        )
        return fallbackValue
      }
    },
  }

  return new Proxy(initialObject, handler)
}

export const mapTextSizeToClass = proxifyWithFallbackValue({
  initialObject: textSizeToClass,
  fallbackValue: textSizeToClass.M,
})

export const mapTextColorToClass = proxifyWithFallbackValue({
  initialObject: textColorToClass,
  fallbackValue: textColorToClass.acai,
  onFallback: (prop) => {
    // Covers for old branding values
    switch (prop) {
      case 'rose':
        return textColorToClass.pitaya
      case 'purple':
        return textColorToClass.acai
      case 'grey':
        return textColorToClass.boulder
    }
  },
})

export const mapTextStyleToClass = proxifyWithFallbackValue({
  initialObject: textStyleToClass,
  fallbackValue: textStyleToClass.normal,
})
export const mapTextAlignToClass = proxifyWithFallbackValue({
  initialObject: textAlignToClass,
  fallbackValue: textAlignToClass.left,
})

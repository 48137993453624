import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  forwardRef,
  LegacyRef,
} from 'react'
import { TInputValidator, getFieldError } from './FormInput.utils'
import { LoaderIcon } from '../../oldBranding/loaderIcon'
import {
  HiOutlineCheckCircle,
  HiOutlineEye,
  HiOutlineEyeOff,
  HiOutlineXCircle,
} from 'react-icons/hi'
export interface IFormInput {
  name: string
  label?: string
  subTitle?: string
  type: string
  placeholder?: string
  isReadonly?: boolean
  wasSubmitted?: boolean
  validators?: TInputValidator[]
  fieldHint?: string
  className?: string
  inputClassName?: string
  initialValue?: string | number
  hasSuccessState?: boolean
  hasValidationIcons?: boolean
  IconBefore?: React.ElementType
  IconAfter?: React.ElementType
  onValidationError?: ({ error }: { error: string }) => void
  customValidator?: (value) => Promise<string> | string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onFocus?: () => void
  onBlur?: () => void
  onKeyDown?: (event) => void
}

const FormInput = forwardRef(
  (
    {
      name,
      label,
      subTitle,
      type,
      placeholder,
      isReadonly = false,
      wasSubmitted = false,
      validators = [],
      className = '',
      inputClassName = '',
      initialValue,
      hasSuccessState = false,
      hasValidationIcons = false,
      IconBefore,
      IconAfter,
      fieldHint,
      onValidationError = () => {},
      customValidator = null,
      onChange = () => {},
      onFocus = () => {},
      onBlur = () => {},
      onKeyDown = () => {},
    }: IFormInput,
    ref: any
  ) => {
    const [value, setValue] = useState(initialValue || '')
    const [alternativeType, setAlternativeType] = useState('')
    const [touched, setTouched] = useState(false)
    const [touching, setTouching] = useState(false)
    const errorMessage = getFieldError(value, validators)
    const [customErrorMessage, setCustomErrorMessage] = useState('')
    const [customValidationInProgress, setCustomValidationInProgress] =
      useState(false)
    const displayErrorMessage =
      customErrorMessage || ((wasSubmitted || touched) && errorMessage)
    const displaySuccessState =
      hasSuccessState &&
      (wasSubmitted || touched) &&
      !errorMessage &&
      !customErrorMessage &&
      !touching &&
      value
    const validated = displayErrorMessage || displaySuccessState
    const displayHint = fieldHint && !validated && !(touched && value)

    const iconColorClass = () => {
      if (validated) {
        return displaySuccessState ? 'text-core-tree' : 'text-error'
      }
      if (touching || (touched && value)) {
        return 'text-acai'
      }
      return 'text-core-grey-mild '
    }

    const togglePasswordVisibility = () => {
      if (!alternativeType) {
        setAlternativeType('text')
      } else {
        setAlternativeType('')
      }
    }

    const runCustomValidator = async () => {
      if (!customValidator || errorMessage) {
        return
      }
      setCustomErrorMessage('')
      setCustomValidationInProgress(true)

      const error = await customValidator(value)
      if (error) {
        setCustomErrorMessage(error)
      }

      setCustomValidationInProgress(false)
    }

    useEffect(() => {
      if (value || (!value && (touching || touched))) {
        runCustomValidator()
      }
    }, [errorMessage, value])

    useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
      if (!onValidationError || typeof onValidationError !== 'function') {
        return
      }

      onValidationError({
        error: errorMessage || null,
      })
    }, [errorMessage])

    useEffect(() => {
      const preventDefaultEvent = (event) => {
        event?.preventDefault()
      }
      if (ref?.current) {
        ref.current.addEventListener('wheel', preventDefaultEvent, {
          passive: false,
        })
        return () =>
          ref?.current?.removeEventListener('wheel', preventDefaultEvent)
      }
    }, [])

    return (
      <div
        key={name}
        className={`
        flex flex-col items-start justify-items-center content-start relative text-base-16 transition-all w-full mt-2 mb-4
        ${className}
      `}
      >
        {label && (
          <label
            htmlFor={`${name}-input`}
            className={`text-2xl font-semibold text-boulder ${
              subTitle ? 'mb-1' : 'mb-3'
            }`}
          >
            {label}
          </label>
        )}

        {subTitle && (
          <div
            id={`${name}-subtitle`}
            className={`text-lg pb-4 ${
              touching ? 'text-core-grey-purple' : 'text-core-grey-mild'
            }`}
          >
            {subTitle}
          </div>
        )}

        <div className={`relative w-full`}>
          <input
            ref={ref as LegacyRef<HTMLInputElement>}
            id={`${name}-input`}
            aria-label={`${name}-input`}
            name={name}
            type={alternativeType || type}
            value={value}
            readOnly={isReadonly}
            onChange={(event) => {
              setValue(event.currentTarget.value)
              onChange(event)
            }}
            onBlur={() => {
              setTouched(true)
              setTouching(false)
              if (onBlur) {
                onBlur()
              }
            }}
            onFocus={() => {
              setTouching(true)
              if (onFocus) {
                onFocus()
              }
            }}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            aria-describedby={displayErrorMessage ? `${name}-error` : undefined}
            className={`
            w-full h-24 pl-10 border-2 outline-0 outline-none shadow-none rounded-full font-mori placeholder-black-60
            ${
              displayErrorMessage
                ? 'border-error text-error placeholder-error'
                : 'border-black-20 focus:border-acai-60 text-black'
            }
            ${
              displaySuccessState
                ? 'border-core-tree text-core-tree'
                : 'border-black-20 focus:border-acai-60 text-black'
            }
            ${IconBefore ? 'pl-20' : ''}
            ${
              IconAfter || hasValidationIcons || type === 'password'
                ? 'pr-20'
                : ''
            }
            transition-all
            ${inputClassName}
          `}
          />

          {IconBefore && (
            <div
              className={`absolute text-4xl left-7 self-center top-1/2 -translate-y-1/2 ${iconColorClass()}`}
            >
              <IconBefore />
            </div>
          )}

          {!(validated && hasValidationIcons) &&
            IconAfter &&
            type !== 'password' && (
              <div
                className={`absolute text-4xl right-7 self-center top-1/2 -translate-y-1/2 ${iconColorClass()}`}
              >
                <IconAfter />
              </div>
            )}

          {hasValidationIcons && hasSuccessState && displaySuccessState && (
            <div className="absolute self-center text-4xl -translate-y-1/2 right-7 text-core-tree top-1/2">
              <HiOutlineCheckCircle />
            </div>
          )}

          {hasValidationIcons && displayErrorMessage && (
            <div className="absolute self-center text-4xl -translate-y-1/2 right-7 text-error top-1/2">
              <HiOutlineXCircle />
            </div>
          )}

          {type === 'password' && !(validated && hasValidationIcons) && (
            <div
              className={`absolute text-4xl right-7 cursor-pointer top-1/2 -translate-y-1/2 ${iconColorClass()}`}
              onClick={togglePasswordVisibility}
            >
              {alternativeType ? <HiOutlineEyeOff /> : <HiOutlineEye />}
            </div>
          )}

          {customValidationInProgress && (
            <div className="absolute self-center text-4xl -translate-y-1/2 right-7 top-1/2 text-error">
              <LoaderIcon
                size="s"
                fillColorClass={
                  displayErrorMessage ? 'fill-error' : 'fill-acai'
                }
              />
            </div>
          )}
        </div>

        {displayErrorMessage && (
          <span
            role="alert"
            id={`${name}-error`}
            className="pt-1 text-lg text-left text-error mt-2"
          >
            {errorMessage || customErrorMessage}
          </span>
        )}

        {displayHint && (
          <span
            id={`${name}-hint`}
            className={`pt-1 text-lg text-left mt-2 ${
              touching ? 'text-core-grey-purple' : 'text-core-grey-mild'
            }`}
          >
            {fieldHint}
          </span>
        )}
      </div>
    )
  }
)

export { FormInput }

let stopped = false
let progressId = 0

export const updateProgress = (
  duration: number,
  onUpdate: (progress: number) => void,
  onDone: () => void
) => {
  const startTime = Date.now()

  function animate() {
    if (stopped) {
      return
    }

    const progress = Math.min((Date.now() - startTime) / duration, 1)
    if (typeof onUpdate === 'function') {
      onUpdate(progress)
    }

    if (progress < 1) {
      progressId = requestAnimationFrame(animate)
    } else {
      cancelAnimationFrame(progressId)
      if (typeof onDone === 'function') {
        onDone()
      }
    }
  }

  function start() {
    stopped = false
    cancelAnimationFrame(progressId)
    progressId = requestAnimationFrame(animate)
  }

  function stop(onStop?: () => void) {
    stopped = true
    cancelAnimationFrame(progressId)
    if (typeof onStop === 'function') {
      onStop()
    }
  }

  return {
    start,
    stop,
  }
}

export type ContentfulProps = {
  w?: number
  h?: number
  q?: string
  bg?: string
  fit?: string
  fm?: string
}

export function buildContentfulSource(
  src: string,
  props: ContentfulProps,
  scale = 1
) {
  const query = new URLSearchParams()
  if (props.w) {
    query.append('w', String(props.w * scale))
  }
  if (props.h) {
    query.append('h', String(props.h * scale))
  }
  if (props.q) {
    query.append('q', props.q)
  }
  if (props.bg) {
    query.append('bg', props.bg)
  }
  if (props.fit) {
    query.append('fit', props.fit)
  }
  if (props.fm) {
    query.append('fm', props.fm)
  }
  return `${src}${query.toString().length ? '?' : ''}${query.toString()}`
}

import React, { ReactNode } from 'react'
import { ContentfulNextJsImage } from '@components/common/ContentfulImage'

export type TBadgeAlertSize = 's' | 'm' | 'l'
export type TBadgeAlertVariant =
  | 'attention'
  | 'success'
  | 'error'
  | 'info'
  | 'muted-info'

export const BadgeAlertMessage = ({
  badge,
  size = 'm',
  children,
  variant = 'info',
  className,
  borderType = 'rounded',
}: {
  badge: { url: string; alt: string }
  size: TBadgeAlertSize
  children: ReactNode
  variant?: TBadgeAlertVariant
  className?: string
  borderType?: 'rounded' | 'square-round' | 'square'
}) => {
  const imageSizeMapper = {
    s: { imageSize: 40, class: 'w-16 h-16' },
    m: { imageSize: 50, class: 'w-20 h-20' },
    l: { imageSize: 60, class: 'w-24 h-24' },
  }

  const textSizeMapper = {
    s: 'text-lg',
    m: 'text-xl',
    l: 'text-2xl',
  }

  const typeClassesMapper = {
    error: 'bg-core-light-error text-core-error',
    attention: 'bg-core-beak text-core-purple',
    info: 'bg-core-light-blue text-core-purple',
    success: 'bg-core-tree text-core-white',
    'muted-info': 'bg-core-grey-20 text-core-purple',
  }

  const borderClassMapper = {
    rounded: 'rounded-full',
    'square-round': 'rounded-lg',
    square: 'rounded-none',
  }

  const textPaddingClassMapper = {
    rounded: 'py-4 pl-6 pr-12',
    'square-round': 'py-4 px-6',
    square: 'py-4 px-6',
  }

  return (
    <div
      className={`flex items-center ${borderClassMapper[borderType]} ${typeClassesMapper[variant]} ${className}`}
    >
      <div
        className={`shrink-0 flex items-center my-2 ml-2 ${imageSizeMapper[size].class}`}
      >
        {badge?.url?.includes('ctfassets') ? (
          <ContentfulNextJsImage
            alt={badge.alt}
            src={badge.url}
            height={imageSizeMapper[size].imageSize}
            width={imageSizeMapper[size].imageSize}
            quality={70} // FIXME: Why don't we convert here?
          />
        ) : (
          <img src={badge?.url} alt={badge?.alt} />
        )}
      </div>
      <div
        className={`font-normal text-left ${textPaddingClassMapper[borderType]} ${textSizeMapper[size]}`}
      >
        {children}
      </div>
    </div>
  )
}

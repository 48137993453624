import { Asset } from 'contentful'

export interface IImage {
  imageUrl: string
  imageTitle: string
}

export const mapContentfulImageAsset = (
  contentfulImageAsset: Asset
): IImage => {
  return {
    imageUrl: contentfulImageAsset?.fields?.file?.url || null,
    imageTitle: contentfulImageAsset?.fields?.title || null,
  }
}

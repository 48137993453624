import React, { useState } from 'react'
import Image, { ImageProps } from 'next/legacy/image'
import { buildContentfulSource } from '@components/lib/buildContentfulSource'

export type TFormat = 'jpg' | 'jpeg' | 'png' | 'webp' | 'gif' | 'avif'

// Please use ContentfulNextJsImage instead - we are slowly migrating from this one
export default function ContentfulImage({
  src,
  w = undefined,
  h = undefined,
  q = undefined,
  bg = undefined,
  fit = undefined,
  fm = undefined,
  lazyLoad = false,
  ...props
}) {
  if (src?.startsWith('//images.ctfassets.net/')) {
    const contentfulProps = { w, h, q, bg, fit, fm }
    const src1x = buildContentfulSource(src, contentfulProps)
    const src2x = buildContentfulSource(src, contentfulProps, 2)
    return (
      <img
        loading={lazyLoad ? 'lazy' : 'eager'}
        src={src1x}
        srcSet={`${src2x} 2x`}
        {...props}
      />
    )
  }

  return <img src={src} loading={lazyLoad ? 'lazy' : 'eager'} {...props} />
}

function getFormatQueryParam(src: string, format: TFormat) {
  const fileExtension = src.split(/[#?]/)[0].split('.').pop().trim()

  if (fileExtension === 'svg') {
    // SVG won't care about the format anyway
    return ''
  }

  if (fileExtension === 'png' && !format) {
    // PNG can have transparent background, AVIF is smaller and also support transparent and if browser also supports it, switch
    return `&fm=avif`
  }

  if (format === 'jpeg' || format === 'jpg') {
    // Override jpg for smaller size
    return `&fm=avif`
  }

  return format ? `&fm=${format}` : ''
}

export const ContentfulNextJsImage = ({
  quality,
  backgroundColour,
  fit,
  format,
  objectFit = 'cover',
  useImageAspectRatio = false,
  blurPlaceholderOn = true,
  ...restProps
}: ImageProps & {
  quality?: number
  backgroundColour?: string
  fit?: 'pad' | 'fill' | 'scale' | 'crop' | 'thumb'
  format?: TFormat
  useImageAspectRatio?: boolean
  blurPlaceholderOn?: boolean
}) => {
  const [aspectRatio, setAspectRatio] = useState('auto')
  const fitQueryParam = fit ? `&fit=${fit}` : ''
  const backgroundQueryParam = backgroundColour
    ? `&bg=rgb:${backgroundColour?.replace(/#/g, '')}`
    : ''

  return (
    <div
      style={{
        aspectRatio,
        display: 'flex',
        ...restProps.style,
      }}
      className={restProps.className}
    >
      <Image
        loader={({ src, width: widthFromLoader }) => {
          const formatQueryParam = getFormatQueryParam(src, format)
          return `${src}?w=${widthFromLoader}&q=${
            quality || 75
          }${fitQueryParam}${backgroundQueryParam}${formatQueryParam}`
        }}
        objectFit={objectFit}
        {...restProps}
        style={{
          backgroundColor: backgroundColour,
        }}
        onLoadingComplete={({ naturalWidth, naturalHeight }) => {
          if (useImageAspectRatio) {
            setAspectRatio(String(naturalWidth / naturalHeight))
          }
        }}
        placeholder={blurPlaceholderOn ? 'blur' : null}
        blurDataURL={
          blurPlaceholderOn
            ? 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
            : ''
        }
      ></Image>
    </div>
  )
}

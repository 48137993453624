import { IFormField } from '@noissue-ui-kit/oldBranding/form'
import { validate as validateEmail } from 'email-validator'
import isEmpty from 'lodash/isEmpty'

export type TInputValidator = 'required' | 'email' | 'password'

const isValidEmail = (value): boolean => {
  return validateEmail(value)
}
const isValidPassword = (value): boolean => {
  const passwordRegex =
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-?;,./{}|":<>[\]\\' ~_]).{8,}/
  return passwordRegex.test(value)
}
const inputValidators: { [name in TInputValidator]: (value) => boolean } = {
  required: (value) => !!value,
  email: isValidEmail,
  password: isValidPassword,
}

export const inputErrors: { [name in TInputValidator]: string } = {
  required: 'This field is required',
  email: 'This field must be a valid email',
  password:
    'Password must be at least 8 characters long, contain lowercase letter, uppercase letter, special characters and numeric value.',
}

export const getFieldError = (value, validators: TInputValidator[]) => {
  let inputError = ''

  if (isEmpty(validators)) {
    return null
  }

  for (const validator of validators) {
    if (!inputValidators[validator](value)) {
      inputError = inputErrors[validator]
      break // short the check early
    }
  }

  return inputError
}

export const fieldHasMatchError = (field: IFormField, fieldValues) => {
  if (
    field.fieldToMatch &&
    fieldValues[field.fieldToMatch] !== fieldValues[field.name]
  ) {
    return true
  }
  return false
}

export const filedHasCustomError = async (
  value,
  customValidator: (value) => Promise<string>
) => {
  if (customValidator) {
    const error = await customValidator(value)
    return !!error
  }
  return false
}

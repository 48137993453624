import {
  KeenSliderHooks,
  KeenSliderOptions,
  KeenSliderPlugin,
  useKeenSlider,
} from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'

import React, { ReactNode, useEffect, useRef, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { IconButton } from '@noissue-ui-kit/oldBranding/iconButton'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'
import { TSliderIndicator } from '@utils/types'
import { updateProgress } from '@utils/SmoothProgress'
import { trackCaroselClick } from '@utils/gtm'
import router from 'next/router'

function Arrow({
  disabled,
  left,
  onClick,
}: {
  disabled: boolean
  left?: boolean
  onClick: (e: any) => void
}) {
  return (
    <>
      <IconButton
        onClick={onClick}
        size="xs"
        className={`absolute top-2/4 -translate-y-1/2 ${
          left ? 'left-[2rem]' : 'right-[2rem]'
        }`}
        disabled={disabled}
        ariaLabel={left ? 'previous' : 'next'}
      >
        {left ? <HiChevronLeft /> : <HiChevronRight />}
      </IconButton>
    </>
  )
}

const ResizePlugin: KeenSliderPlugin = (slider) => {
  const observer = new ResizeObserver(function () {
    slider.update()
  })

  slider.on('created', () => {
    observer.observe(slider.container)
  })
  slider.on('destroyed', () => {
    observer.unobserve(slider.container)
  })
}

const AutoSwitch = (autoSwitchSpeed: number) => (slider) => {
  if (autoSwitchSpeed <= 0) {
    return
  }
  let timeout: ReturnType<typeof setTimeout>
  let mouseOver = false
  function clearNextTimeout() {
    clearTimeout(timeout)
  }
  function nextTimeout() {
    clearTimeout(timeout)
    if (mouseOver) {
      return
    }
    timeout = setTimeout(() => {
      slider.next()
    }, autoSwitchSpeed)
  }
  slider.on('created', () => {
    slider.container.addEventListener('mouseover', () => {
      mouseOver = true
      clearNextTimeout()
    })
    slider.container.addEventListener('mouseout', () => {
      mouseOver = false
      nextTimeout()
    })
    nextTimeout()
  })
  slider.on('dragStarted', clearNextTimeout)
  slider.on('animationEnded', nextTimeout)
  slider.on('updated', nextTimeout)
}

const fillProgressLines = (
  progressLines: NodeListOf<HTMLElement>,
  index: number
) => {
  progressLines.forEach((progressLine, idx) => {
    if (idx < index) {
      const parentWidth = progressLine?.parentElement?.clientWidth
      if (parentWidth) {
        progressLine.style.width = parentWidth + 'px'
      }
    } else {
      progressLine.style.width = '0'
    }
  })
}

interface IKeenSliderWrapper {
  breakpoints?: {
    [key: string]: Omit<
      KeenSliderOptions<unknown, unknown, KeenSliderHooks>,
      'breakpoints'
    >
  }
  className?: string
  initialConfig: {
    origin?: number | 'auto' | 'center'
    number?: number | (() => number)
    perView?: number | 'auto' | (() => number | 'auto')
    spacing?: number | (() => number)
  }
  children: ReactNode[]
  isShowArrows?: boolean
  hideArrowsWhenDisabled?: boolean
  loop?: boolean
  autoSwitchSpeed?: number
  indicator?: TSliderIndicator
  trackingIdentifier: string
  progressIndicatorClassName?: string
}

export function KeenSliderWrapper({
  breakpoints = {},
  initialConfig,
  className = '',
  children,
  isShowArrows = false,
  hideArrowsWhenDisabled = false,
  loop = false,
  autoSwitchSpeed = 0,
  indicator = 'none',
  trackingIdentifier,
  progressIndicatorClassName = '',
}: IKeenSliderWrapper) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [options, setOptions] = useState({})
  const timeoutRef = useRef(null)
  const [isCanceledProgress, setIsCanceledProgress] = useState(false)

  const enableAutoSwitch = autoSwitchSpeed > 0 && indicator !== 'progress line'
  const showDots = React.Children.count(children) > 1 && indicator === 'dots'
  const showProgressLine =
    React.Children.count(children) > 1 &&
    autoSwitchSpeed > 0 &&
    indicator === 'progress line'

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    options,
    [
      ResizePlugin,
      enableAutoSwitch ? AutoSwitch(autoSwitchSpeed) : null,
    ].filter(Boolean)
  )

  const leftArrowDisabled = !loop && currentSlide === 0
  const rightArrowDisabled =
    !loop && currentSlide === instanceRef.current?.track?.details?.maxIdx

  const changed = () => {
    const {
      loop: instanceLoop,
      breakpoints: instanceBreakpoints,
      slides: instanceSlides,
    } = instanceRef?.current?.options || {}
    if (loop !== instanceLoop) {
      return true
    }
    if (!isEqual(breakpoints, instanceBreakpoints)) {
      return true
    }
    if (!isEqual(initialConfig, instanceSlides)) {
      return true
    }
    return false
  }

  useEffect(() => {
    setOptions({
      breakpoints: breakpoints,
      slides: initialConfig,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel)
      },
      loop: loop,
    })
  }, [changed()])

  useEffect(() => {
    if (!showProgressLine) {
      return
    }

    if (!instanceRef.current) {
      return
    }

    if (autoSwitchSpeed <= 0) {
      return
    }

    const progressLines: NodeListOf<HTMLElement> =
      document.querySelectorAll('.progressLine')

    if (progressLines.length < 1) {
      return
    }

    fillProgressLines(progressLines, currentSlide)

    const { start, stop } = updateProgress(
      autoSwitchSpeed,
      (progress) => {
        const progressLine = progressLines.item(currentSlide)
        const parentWidth = progressLine?.parentElement?.clientWidth
        const increasingWidth = (parentWidth * progress).toFixed(2) + 'px'
        progressLine.style.width = increasingWidth
      },
      () => {
        instanceRef.current?.moveToIdx(currentSlide + 1)
      }
    )

    if (isCanceledProgress) {
      stop(() => {
        fillProgressLines(progressLines, currentSlide)

        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
          timeoutRef.current = null
        }

        timeoutRef.current = setTimeout(() => {
          setIsCanceledProgress(false)
          instanceRef.current?.moveToIdx(currentSlide)
        }, 0)
      })

      return
    }

    start()
  }, [
    instanceRef.current,
    isCanceledProgress,
    currentSlide,
    autoSwitchSpeed,
    showProgressLine,
  ])

  const handleProgressLineClick = (index: number) => {
    setIsCanceledProgress(true)

    instanceRef.current?.moveToIdx(index)

    const progressLines: NodeListOf<HTMLElement> =
      document.querySelectorAll('.progressLine')

    if (progressLines.length < 1) {
      return
    }

    fillProgressLines(progressLines, index)
  }

  return (
    <>
      <div ref={sliderRef} className={`keen-slider ${className}`}>
        {/* For children components it's mandatory to have keen-slider__slide class to make slider work */}
        {children}
      </div>

      {instanceRef.current && isShowArrows && (
        <>
          {hideArrowsWhenDisabled && leftArrowDisabled ? null : (
            <Arrow
              left
              onClick={(e: any) => {
                e.stopPropagation()
                trackCaroselClick({
                  direction: 'left',
                  componentIdentifier: trackingIdentifier,
                  pageUrl: router.asPath,
                })
                instanceRef.current?.prev()
              }}
              disabled={leftArrowDisabled}
            />
          )}

          {hideArrowsWhenDisabled && rightArrowDisabled ? null : (
            <Arrow
              onClick={(e: any) => {
                e.stopPropagation()
                trackCaroselClick({
                  direction: 'right',
                  componentIdentifier: trackingIdentifier,
                  pageUrl: router.asPath,
                })
                instanceRef?.current?.next()
              }}
              disabled={rightArrowDisabled}
            />
          )}
        </>
      )}

      {showDots && instanceRef.current && (
        <div
          className={`absolute bottom-0 flex gap-[12px] ${progressIndicatorClassName}`}
        >
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx)
                }}
                style={{
                  width: '104px',
                  height: '4px',
                  background:
                    currentSlide === idx
                      ? 'linear-gradient(to right, var(--core-white) 70%, rgba(255,255,255, 0.4) 70% 100%)'
                      : 'var(--core-white)',
                  opacity: currentSlide === idx ? 1 : 0.4,
                }}
              ></button>
            )
          })}
        </div>
      )}

      {showProgressLine && instanceRef.current && (
        <div
          className={`absolute bottom-0 w-full flex gap-[12px] justify-center xs:justify-start ${progressIndicatorClassName}`}
        >
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => handleProgressLineClick(idx)}
                className="w-[60px] xs:w-[104px] h-[4px]"
                style={{
                  background: 'rgba(255, 255, 255, 0.4)',
                }}
              >
                <div
                  className="h-full bg-core-white progressLine"
                  style={{
                    width: '0',
                  }}
                ></div>
              </button>
            )
          })}
        </div>
      )}
    </>
  )
}

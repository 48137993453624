import React from 'react'

interface ILoaderIcon {
  size?: 'xs' | 's' | 'm' | 'l'
  className?: string
  fillColorClass?: string
}

export function LoaderIcon({
  size,
  className,
  fillColorClass = 'fill-core-white',
}: ILoaderIcon) {
  const sizeClassMapper = {
    xs: 'h-6 w-6',
    s: 'h-10 w-10',
    m: 'h-16 w-16',
    l: 'h-20 w-20',
  }
  return (
    <svg
      version="1.1"
      id="L5"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 0 0"
      xmlSpace="preserve"
      className={`${sizeClassMapper[size]} ${className} -my-2`}
    >
      <circle className={fillColorClass} stroke="none" cx="25" cy="50" r="6">
        <animateTransform
          attributeName="transform"
          dur="1s"
          type="translate"
          values="0 10 ; 0 7 ; 0 0 ; 0 -7 ; 0 -10 ; 0 -7 ; 0 0 ; 0 7 ; 0 10"
          repeatCount="indefinite"
          begin="0.1"
        />
      </circle>
      <circle className={fillColorClass} stroke="none" cx="50" cy="50" r="6">
        <animateTransform
          attributeName="transform"
          dur="1s"
          type="translate"
          values="0 10 ; 0 7 ; 0 0 ; 0 -7 ; 0 -10 ; 0 -7 ; 0 0 ; 0 7 ; 0 10"
          repeatCount="indefinite"
          begin="0.2"
        />
      </circle>
      <circle className={fillColorClass} stroke="none" cx="75" cy="50" r="6">
        <animateTransform
          attributeName="transform"
          dur="1s"
          type="translate"
          values="0 10 ; 0 7 ; 0 0 ; 0 -7 ; 0 -10 ; 0 -7 ; 0 0 ; 0 7 ; 0 10"
          repeatCount="indefinite"
          begin="0.3"
        />
      </circle>
    </svg>
  )
}
